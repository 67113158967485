import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="h-60 bg-red pt-10">
      <div className="w-10/12 mx-auto">
        <SocialMediaIcons />
        <div className="md:flex justify-center md:justify-between text-center ">
          <p className="font-playfair font-semibold text-2xl text-white">
            june cho
          </p>
          <p className="font-playfair text-md text-white">
            ©2023 All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;





// import SocialMediaIcons from "../components/SocialMediaIcons";

// const Footer = () => {
//   return (
//     <footer className="h-30 bg-red ">
//       <div className="w-1/2 mx-auto ">
//         {/* <SocialMediaIcons /> */}
//         <div className="md:flex justify-center md:justify-between text-center mx-auto my-auto">
//         <SocialMediaIcons />
//           {/* <p className="font-playfair font-semibold text-2xl text-white">
//             june cho
            
//           </p> */}
//           <p className="font-playfair text-md text-white  mx-auto my-auto">
//             developed with ♥️ in san francisco · all rights reserved ©2023
//           </p>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;
