import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import meter1 from "../assets/meter1.svg";
import meter2 from "../assets/meter2.svg";
import meter3 from "../assets/meter3.svg";
import meter4 from "../assets/meter4.svg";
import meter5 from "../assets/meter5.svg";
import meter6 from "../assets/meter6.svg";
import meter7 from "../assets/meter7.svg";

// import arrow1 from "../assets/arrow1.svg";
// import arrow2 from "../assets/arrow2.svg";
// import colorSharp from "../assets/color-sharp.png"

export const Testimonials = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7
    },
    halfdesktop: {
      breakpoint: { max: 1500, min: 700 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section id="talks" className="pt-32 pb-16 mt-10 ">
       <motion.div
        className="md:w-1/3 text-left md:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <p className="font-playfair font-semibold text-8xl mb-5 text-red">
          Talks
        </p>
        {/* <LineGradient width="mx-auto" /> */}
        <p className="mt-10 mx-4">
          i ♥️ to chat about design and dev stuff
        </p>
      </motion.div>

        <div className="container mx-auto">
            <div className="row mx-auto">
                <div className="col-12 mt-40 ">
                    <div className="skill-bx wow zoomIn">
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item mx-20">
                                <img src={meter1} alt="Image" />
                                <div className="mx-auto text-lg font-bold mt-8 text-center">
                                  <h5>Zeplin: Harmony<br></br>
                                  2023</h5>
                                </div>
                            </div>
                            <div className="item mx-20">
                                <img src={meter2} alt="Image" />
                                <div className="mx-auto text-lg font-bold mt-8 text-center">
                                  <h5>GitKraken: Gitkon <br></br>
                                  2022 </h5>
                                </div>
                            </div>
                            <div className="item mx-20">
                                <img src={meter3} alt="Image" />
                                <div className="mx-auto text-lg font-bold mt-8 text-center">
                                  <h5>UX Test: Savvy UX <br></br>
                                  2022</h5>
                                </div>
                            </div>
                            <div className="item mx-20">
                                <img src={meter1} alt="Image" />
                                <div className="mx-auto text-lg font-bold mt-8 text-center">
                                  <h5>Zeplin: Harmony<br></br>
                                  2022</h5>
                                </div>
                            </div>
                            <div className="item mx-20">
                                <img src={meter4} alt="Image" />
                                <div className="mx-auto text-lg font-bold mt-8 text-center">
                                  <h5>Jina: Clarity<br></br>
                                  2021</h5>
                                </div>
                            </div>
                            <div className="item mx-20">
                                <img src={meter5} alt="Image" />
                                <div className="mx-auto text-lg font-bold mt-8 text-center">
                                  <h5>Pluralsight: Live<br></br>
                                  2019</h5>
                                </div>
                            </div>
                            <div className="item mx-20">
                                <img src={meter6} alt="Image" />
                                <div className="mx-auto text-lg font-bold mt-8 text-center">
                                  <h5>Microsoft: Ready<br></br>
                                  2019</h5>
                                </div>
                            </div>
                            <div className="item mx-20">
                                <img src={meter7} alt="Image" />
                                <div className="mx-auto text-lg font-bold mt-8 text-center">
                                  <h5>J & J: Botathon<br></br>
                                  2018</h5>
                                </div>
                            </div>
                            {/* <div className="item mx-20">
                                <img src={meter7} alt="Image" />
                                <div className="mx-auto text-lg font-bold mt-8 text-center">
                                  <h5>Johnson & Johnson Botathon<br></br>
                                  2018</h5>
                                </div>
                            </div> */}
                            
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        {/* <img className="background-image-left" src={colorSharp} alt="Image" /> */}
    </section>
  )
}

export default Testimonials;



// const Testimonials = () => {
//   return (
//     <section id="speaking" className="pt-32 pb-16">
//       {/* HEADING */}
//       <motion.div
//         className="md:w-1/3 text-left md:text-left"
//         initial="hidden"
//         whileInView="visible"
//         viewport={{ once: true, amount: 0.5 }}
//         transition={{ duration: 0.5 }}
//         variants={{
//           hidden: { opacity: 0, x: -50 },
//           visible: { opacity: 1, x: 0 },
//         }}
//       >
//         <p className="font-playfair font-semibold text-8xl mb-5 text-red">
//           Speaking
//         </p>
//         {/* <LineGradient width="mx-auto" /> */}
//         <p className="mt-10 mx-4">
//           i ♥️ to chat about design and dev stuff
//         </p>
//       </motion.div>

//       {/* TESTIMONIALS */}
//       <div className="md:flex md:justify-between gap-8">
//         <motion.div
//           className="mx-auto relative bg- max-w-[400px] h-[350px] flex flex-col justify-end p-16 mt-48
//             before:absolute before:top-[20px] before:-ml-[90px] before:left-1/2 before:content-person1"
//           initial="hidden"
//           whileInView="visible"
//           viewport={{ once: true, amount: 0.5 }}
//           transition={{ duration: 0.6 }}
//           variants={{
//             hidden: { opacity: 0, scale: 0.8 },
//             visible: { opacity: 1, scale: 1 },
//           }}
//         >
//           <p className="font-playfair text-6xl"></p>
//           <p className="text-center text-xl">
//             Zeplin: <br></br>
//             Harmony <br></br>
//             2023
//           </p>
//         </motion.div>

//         <motion.div
//           className="mx-auto relative bg-purple-400 max-w-[400px] h-[350px] flex flex-col justify-end p-16 mt-48
//             before:absolute before:top-[20px] before:-ml-[90px] before:left-1/2 before:content-person2"
//           initial="hidden"
//           whileInView="visible"
//           viewport={{ once: true, amount: 0.5 }}
//           transition={{ delay: 0.2, duration: 0.6 }}
//           variants={{
//             hidden: { opacity: 0, scale: 0.8 },
//             visible: { opacity: 1, scale: 1 },
//           }}
//         >
//           <p className="font-playfair text-6xl"></p>
//           <p className="text-center text-xl">
//             Gitkraken: <br></br>
//             GitKon <br></br>
//             2022
            
//           </p>
//         </motion.div>

//         <motion.div
//           className="mx-auto relative bg-pink-400 max-w-[400px] h-[350px] flex flex-col justify-end p-16 mt-48
//             before:absolute before:top-[20px] before:-ml-[90px] before:left-1/2 before:content-person3"
//           initial="hidden"
//           whileInView="visible"
//           viewport={{ once: true, amount: 0.5 }}
//           transition={{ delay: 0.4, duration: 0.6 }}
//           variants={{
//             hidden: { opacity: 0, scale: 0.8 },
//             visible: { opacity: 1, scale: 1 },
//           }}
//         >
//           <p className="font-playfair text-6xl"></p>
//           <p className="text-center text-xl">
//             UX Test: <br></br>
//             Savvy UX <br></br>
//             2022
//           </p>
//         </motion.div>

//         <motion.div
//           className="mx-auto relative bg-green-500 max-w-[400px] h-[350px] flex flex-col justify-end p-16 mt-48
//             before:absolute before:top-[20px] before:-ml-[90px] before:left-1/2 before:content-person4"
//           initial="hidden"
//           whileInView="visible"
//           viewport={{ once: true, amount: 0.5 }}
//           transition={{ delay: 0.4, duration: 0.6 }}
//           variants={{
//             hidden: { opacity: 0, scale: 0.8 },
//             visible: { opacity: 1, scale: 1 },
//           }}
//         >
//           <p className="font-playfair text-6xl"></p>
//           <p className="text-center text-xl">
//             Jina Anne: <br></br>
//             Clarity <br></br>
//             2021
//           </p>
//         </motion.div>

       
//       </div>
//     </section>
//   );
// };

// export default Testimonials;